.ant-modal-footer .ant-btn-primary {
    @apply bg-green-500 border-none
}

.ant-modal-content {
    @apply dark:bg-gray-900
}

.ant-modal-header {
    @apply dark:bg-gray-900 dark:border-gray-800 p-4 pr-14
}

.ant-modal-body {
    @apply p-4
}

.ant-modal-close {
    @apply top-0
}
.ant-modal-title {
    @apply min-h-[2rem] flex items-center
}
