@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-size: 14px;
    }
    html, body, #root {
        font-family: 'Nunito', sans-serif !important;
        @apply h-full overflow-hidden bg-[#EAEDED] dark:text-white dark:bg-gray-900
    }

    h1 {
        @apply dark:text-white font-semibold text-3xl
    }

    h2 {
        @apply dark:text-white font-semibold text-2xl
    }

    h3 {
        @apply dark:text-white font-semibold text-xl
    }

    h4 {
        @apply dark:text-white font-semibold text-lg
    }

    h5 {
        @apply dark:text-white font-semibold text-base
    }

    h6 {
        @apply dark:text-white font-semibold text-sm
    }
}
