.page-container {
    @apply container mx-auto md:px-5
}
.img-cover {
    @apply w-full h-full object-cover
}
.img-contain {
    @apply w-full h-full object-contain
}

.ant-tooltip-inner {
    @apply text-xs font-semibold leading-normal
}

.editor-content {
    @apply space-y-2
}

.editor-content h1,
.editor-content h2,
.editor-content h3,
.editor-content h4,
.editor-content h5,
.editor-content h6
{
    @apply font-bold !my-4 text-base dark:text-white
}

.editor-content a {
    @apply link
}

.text-mute {
    @apply text-[#6c757d] dark:text-gray-300
}

.filter-box {
    @apply lg:flex items-center justify-between lg:space-x-5 space-y-5 lg:space-y-0
}

.filter-box-buttons {
    @apply block lg:flex space-y-4 lg:space-y-0 lg:space-x-4 whitespace-nowrap
}
.filter-box-button {
    @apply w-full lg:w-auto lg:flex-1 justify-center
}

.header-info-box-desktop {
    @apply lg:inline-flex lg:items-center lg:bg-gray-100 lg:h-10 lg:px-4 lg:text-[#6c757d] lg:dark:text-gray-300 lg:font-bold lg:rounded-full lg:dark:bg-gray-900 lg:dark:text-gray-300
}

.header-info-box-mobile {
    @apply inline-flex space-x-2 dark:text-gray-300 font-bold text-mute
}

.header-info-box {
    @apply header-info-box-mobile header-info-box-desktop whitespace-nowrap text-xs
}

.header-action-icon {
    @apply text-lg text-mute w-7 h-7 inline-flex justify-center items-center shrink-0
}

.border-color {
    @apply border-gray-200 dark:border-gray-700
}

.select-box {
    @apply border p-1 rounded dark:bg-gray-800 dark:border-gray-700 focus:outline-none w-full
}

.ant-upload-list-item-card-actions-btn {
    @apply opacity-100 lg:opacity-0
}

.price-box {
    @apply text-[10px] font-medium border border-indigo-300 text-indigo-500 rounded py-[2px] px-[4px] dark:border-secondary dark:text-secondary whitespace-nowrap
}

.link {
    @apply text-blue-500 hover:text-blue-700 dark:text-blue-300 dark:hover:text-blue-400
}

.table-photo {
    @apply w-10 h-10 flex mx-auto
}

.status {
    @apply font-normal !px-1 py-0.5 !text-[11px]
}

.status-green {
    @apply text-green-600 border border-green-500 dark:text-green-300 dark:border-green-300
}

.status-gray {
    @apply text-gray-600 border border-gray-500 dark:text-gray-300 dark:border-gray-300
}

.status-yellow {
    @apply text-yellow-600 border border-yellow-500 dark:text-yellow-300 dark:border-yellow-300
}

.status-red {
    @apply text-red-600 border border-red-500 dark:text-red-300 dark:border-red-300
}

.status-blue {
    @apply text-blue-600 border border-blue-500 dark:text-blue-300 dark:border-blue-300
}

.status-indigo {
    @apply text-indigo-600 border border-indigo-500 dark:text-indigo-300 dark:border-indigo-300
}

.badge-status .ant-badge-status-text {
    @apply hidden
}

.table {
    @apply border border-color w-full
}

.table tr th,
.table tr td {
    @apply p-2 border border-color text-sm
}

.tox .tox-dialog, .tox .tox-dialog__header, .tox .tox-dialog__footer {
    @apply dark:bg-gray-800
}

.tox .tox-label, .tox .tox-toolbar-label, .tox .tox-dialog__body-nav-item {
    @apply !text-white
}

.tox .tox-dialog__body-nav-item--active {
    @apply dark:!text-secondary !text-primary
}

.tox .tox-dropzone {
    @apply dark:bg-gray-700 dark:border-gray-500
}

.tox .tox-dialog__body-nav-item--active {
    @apply dark:border-secondary border-primary
}

.tox .tox-listboxfield .tox-listbox--select, .tox .tox-textarea, .tox .tox-textarea-wrap .tox-textarea:focus, .tox .tox-textfield, .tox .tox-toolbar-textfield {
    @apply border-color
}
