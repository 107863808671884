.auth {
    @apply h-full flex
}

.auth-aside {
    @apply lg:w-[420px] bg-white lg:p-16 p-5 dark:bg-gray-900 flex flex-col h-full justify-between
}

.auth-main {
    @apply hidden lg:flex h-full flex-1
}

