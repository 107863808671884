@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import '/node_modules/antd/dist/antd.css';
@import "tailwind.css";
@import "./ui/reset.css";
@import "./ui/ant-design.css";
@import "./ui/button.css";
@import "./ui/form.css";
@import "./ui/style.css";
@import "./ui/sweetalert.css";
@import "./ui/table.css";
@import "./ui/tailwind.css";
@import "./ui/typography.css";
@import "./ui/dark-mode.css";
@import "./ui/dropdown.css";
@import "./ui/tab.css";
@import "./ui/auth.css";
