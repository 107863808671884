.ant-table-pagination.ant-table-pagination-right {
    @apply mr-4 !mt-4
}

.ant-table-pagination li,
.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
    @apply flex items-center justify-center text-xs dark:text-gray-300
}

.ant-pagination-jump-next .ant-pagination-item-container, .ant-pagination-jump-prev .ant-pagination-item-container {
    @apply flex items-center justify-center
}

.ant-pagination-item a {
    @apply dark:text-gray-300
}

.ant-pagination-item:hover a {
    @apply dark:text-secondary text-primary
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    @apply dark:!text-gray-300
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    @apply dark:!text-gray-300
}

.ant-pagination-item-active {
    @apply bg-gray-300 !border-none dark:bg-secondary
}

.ant-spin-nested-loading > div > .ant-spin {
    max-height: initial;
    @apply h-full dark:bg-gray-900/70
}

.ant-spin-nested-loading .ant-spin-dot-item {
    @apply dark:bg-secondary
}

.ant-pagination-item-active a {
    @apply !text-gray-700 !font-semibold
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    top: -5px;
}

.ant-table-filter-dropdown-btns .ant-btn-primary {
    @apply text-gray-600
}

.ant-table-thead .ant-table-cell {
    @apply font-semibold
}

.ant-modal-header {
    @apply bg-gray-700 text-white pr-16
}

.ant-modal-title {
    @apply !text-white
}

.ant-modal-close {
    @apply -top-1
}

.ant-modal-close svg {
    @apply text-white
}

.ant-select-clear {
    top: 42%;
}

.ant-input-search .ant-input-affix-wrapper {
    @apply h-8
}

.ant-input-search .ant-input-clear-icon {
    @apply inline-flex items-center
}

.ant-input-number {
    @apply w-full
}

.ant-select {
    @apply w-full
}

.ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.7);
}

.ant-switch {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), linear-gradient(to right, #fff, #fff) !important;
}

.ant-switch-checked {
    background: #1890ff !important;
}

.ant-alert-success {
    @apply bg-green-100 border-green-300
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    vertical-align: 2px;
}

.ant-notification-notice {
    @apply dark:bg-gray-700 dark:border dark:border-gray-600
}

.ant-notification-notice-message {
    @apply dark:text-white
}

.ant-notification-notice-description {
    @apply dark:text-gray-300
}

.ant-notification-notice-close {
    @apply dark:text-gray-300
}

.ant-alert-info {
    @apply dark:bg-blue-800 dark:border-none
}
.ant-alert-info .ant-alert-message {
    @apply dark:text-gray-300
}

.ant-alert-info .ant-alert-message .link {
    @apply dark:text-blue-200
}

.ant-alert-info .link {
    @apply dark:text-blue-700
}

.ant-picker-panel-container {
    @apply dark:bg-gray-800 dark:border dark:border-gray-700
}

.ant-picker-header {
    @apply dark:border-gray-700 dark:text-gray-300
}

.ant-picker-header button {
    @apply dark:text-white
}

.ant-picker-panel {
    @apply dark:border-gray-700
}

.ant-picker-content th {
    @apply dark:text-white
}

.ant-picker-cell {
    @apply dark:text-gray-300
}

.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
    @apply dark:bg-secondary dark:text-gray-900
}

.ant-picker-input > input {
    @apply dark:text-gray-300
}

.ant-picker-focused .ant-picker-separator,
.ant-picker-clear,
.ant-picker-suffix {
    @apply dark:text-gray-300
}

.ant-picker-clear {
    @apply dark:bg-gray-800
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    @apply dark:bg-secondary-300
}

.ant-picker-cell-in-view.ant-picker-cell-in-range .ant-picker-cell-inner {
    @apply dark:text-gray-900
}

.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    @apply dark:bg-secondary dark:text-gray-900
}

.ant-select-clear {
    @apply dark:bg-gray-800 dark:text-gray-300
}

.ant-statistic {
    @apply dark:bg-gray-700 dark:border-gray-600
}

.ant-statistic-title {
    @apply dark:text-white
}

.ant-statistic-content {
    @apply dark:text-secondary
}

.ant-tabs-nav {
    @apply dark:!bg-gray-800 dark:!border-gray-700
}

.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before {
    @apply dark:!border-gray-700
}

.ant-tabs-tab .ant-tabs-tab-btn {
    @apply dark:!text-gray-300
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    @apply dark:!text-secondary
}

.ant-tabs-ink-bar {
    @apply dark:!bg-secondary
}

.ant-upload-list-item-info .anticon-loading .anticon, .ant-upload-list-item-info .ant-upload-text-icon .anticon {
    @apply dark:text-gray-300
}

.ant-upload-list-item-info .ant-btn-icon-only.ant-btn-sm svg {
    @apply dark:text-gray-300
}

.ant-collapse {
    @apply dark:bg-gray-800 dark:border-gray-700
}

.ant-collapse-content {
    @apply dark:!bg-gray-800 dark:!border-gray-700
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    @apply dark:bg-gray-700 dark:text-white
}

.ant-collapse > .ant-collapse-item {
    @apply dark:!border-gray-800 dark:!bg-gray-700
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-right > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
    @apply dark:!border-gray-700
}

.ant-radio-button-wrapper {
    @apply dark:bg-gray-600 dark:text-gray-300
}

.ant-radio-button-wrapper-checked {
    @apply dark:!bg-secondary dark:!text-gray-900
}

.ant-picker-range-arrow::before {
    @apply dark:bg-gray-700
}

.ant-picker-range-arrow {
    @apply dark:bg-none
}

.ant-radio-group {
    @apply dark:border-none dark:text-gray-300
}

.ant-radio-button-wrapper {
    @apply focus-within:!outline-none focus-within:!shadow-none dark:border-none
}

.ant-alert-warning {
    @apply dark:bg-[#ff8800] dark:border-none
}
.ant-alert-warning .ant-alert-message {
    @apply dark:!text-white
}

.ant-alert-warning .ant-alert-message .link {
    @apply text-gray-100
}

.ant-alert-success {
    @apply dark:bg-[#007E33] dark:border-none
}

.ant-alert-success .ant-alert-message {
    @apply dark:text-white
}

.ant-empty {
    @apply dark:bg-gray-800
}

.ant-empty-image svg {
    @apply dark:text-gray-300
}

.ant-empty-description {
    @apply dark:text-gray-300
}

.ant-table-placeholder .ant-table-cell {
    @apply dark:hover:!bg-gray-800 dark:text-gray-300
}

.ant-picker-separator svg {
    @apply dark:text-gray-300
}

.ant-pagination-jump-prev .ant-pagination-item-link-icon, .ant-pagination-jump-next .ant-pagination-item-link-icon {
    @apply !opacity-100
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    @apply !hidden
}

.ant-picker-footer {
    @apply dark:border-gray-700
}

.ant-picker-range-wrapper {
    @apply !min-w-fit lg:w-[284px]
}
.ant-picker-panel-container .ant-picker-panels {
    @apply flex flex-col lg:flex-row
}

.ant-tabs-nav-list {
    @apply overflow-auto
}

.ant-modal-body  {
    @apply p-3 lg:p-6
}

.ant-modal-header {
    @apply py-3 pl-3 pr-14 lg:py-6 lg:pl-6 lg:pr-32
}

.ant-carousel .slick-dots li button {
    @apply h-2
}

.ant-carousel .slick-dots li.slick-active button {
    @apply dark:bg-secondary
}

.ant-modal-centered {
    @apply top-5 bottom-5
}

.ant-radio-disabled + span {
    @apply dark:text-white dark:opacity-50
}

.ant-modal-body {
    @apply dark:text-white
}

.ant-tabs-nav-more {
    @apply dark:text-white text-base
}

.ant-input-show-count-suffix {
    @apply dark:text-white
}

.ant-picker-content th, .ant-picker-content td {
    @apply text-xs !w-5
}

.ant-alert-error {
    @apply dark:bg-[#f8a092] dark:border-none
}
.ant-alert-error .ant-alert-message {
    @apply dark:text-red-800
}

.ant-modal-close {
    @apply top-0
}

.ant-picker-datetime-panel .ant-picker-time-panel,
.ant-picker-time-panel-column:not(:first-child) {
    @apply dark:border-gray-800
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
    @apply dark:text-gray-200 text-xs flex items-center justify-center w-full !pl-0
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    @apply dark:bg-gray-800
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
    @apply dark:bg-gray-600
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    @apply dark:bg-secondary dark:text-gray-900
}

.ant-picker-ranges .ant-picker-ok .ant-btn-primary {
    @apply dark:bg-secondary dark:border-none dark:text-gray-900 dark:drop-shadow-none !text-xs px-8 py-2 h-auto
}
.ant-picker-ranges .ant-picker-now .ant-picker-now-btn {
    @apply dark:text-gray-200
}

.ant-picker-cell-disabled {
    @apply opacity-25
}

.ant-picker-today-btn {
    @apply dark:text-secondary hover:dark:text-secondary text-primary
}

.ant-picker-panel .ant-picker-footer {
    @apply border-color
}

.ant-upload-list-picture-card .ant-upload-list-item {
    @apply !w-[104px] !h-[104px]
}
