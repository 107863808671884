.btn.ant-btn:hover, .btn.ant-btn:focus, .btn.ant-btn:active {
    color: initial;
    background: initial;
    border: initial !important;
}

.btn.ant-btn {
    border: initial !important;
    box-shadow: initial;
}

.btn {
    @apply h-10 px-3 transition-colors !border-none duration-150 !rounded !text-sm !flex items-center leading-4 !font-semibold antialiased whitespace-nowrap cursor-pointer
}

.btn--sm {
    @apply h-6 px-2 !text-xs
}

.btn--action {
    @apply !h-6 !w-6 !text-xs items-center inline-flex justify-center !p-0
}

.btn[disabled] {
    @apply opacity-50 cursor-not-allowed
}

.btn.ant-btn-sm {
    @apply h-6 p-0 w-6 text-center justify-center
}

.btn.ant-btn-lg {
    @apply h-10 px-4
}

.btn .ant-btn-loading-icon {
    @apply flex items-center justify-center
}

.btn--block {
    @apply justify-center w-full
}

.btn--red {
    @apply !text-red-100 !bg-red-600 hover:!bg-red-700 hover:!text-red-100
}

.btn--green {
    @apply !text-green-100 !bg-green-600 hover:!bg-green-700 hover:!text-green-100
}

.btn--blue {
    @apply !text-blue-100 !bg-blue-600 hover:!bg-blue-700 hover:!text-blue-100
}

.btn--gray {
    @apply !text-gray-100 !bg-gray-500 hover:!bg-gray-600 hover:!text-gray-100
}

.btn--pink {
    @apply !text-pink-100 !bg-pink-500 hover:!bg-pink-600 hover:!text-pink-100
}

.btn--purple {
    @apply !text-purple-100 !bg-purple-500 hover:!bg-purple-600 hover:!text-purple-100
}

.btn--indigo {
    @apply !text-indigo-100 !bg-indigo-500 hover:!bg-indigo-600 hover:!text-indigo-100
}

.btn--slate {
    @apply !text-slate-100 !bg-slate-500 hover:!bg-slate-600 hover:!text-slate-100
}

.btn--zinc {
    @apply !text-zinc-100 !bg-zinc-500 hover:!bg-zinc-600 hover:!text-zinc-100
}

.btn--orange {
    @apply !text-orange-100 !bg-orange-500 hover:!bg-orange-600 hover:!text-orange-100
}

.btn--amber {
    @apply !text-amber-100 !bg-amber-500 hover:!bg-amber-600 hover:!text-amber-100
}

.btn--yellow {
    @apply !text-yellow-100 !bg-yellow-500 hover:!bg-yellow-600 hover:!text-yellow-100
}

.btn--lime {
    @apply !text-lime-100 !bg-lime-500 hover:!bg-lime-600 hover:!text-lime-100
}

.btn--sky {
    @apply !text-sky-100 !bg-sky-500 hover:!bg-sky-600 hover:!text-sky-100
}

.btn--white {
    @apply !text-gray-800 !bg-white hover:!bg-gray-200 hover:!text-gray-800
}

.btn--primary {
    @apply !text-white !bg-primary hover:!bg-primary-900 dark:!bg-secondary-800 dark:!text-gray-900 dark:hover:!bg-secondary
}


.btn--red-outline {
    @apply !text-red-600 !border border-red-600 hover:!bg-red-700 hover:!text-red-100
}

.btn--green-outline {
    @apply !text-green-100 !border !border-green-600 hover:!bg-green-700 hover:!text-green-100
}

.btn--blue-outline {
    @apply !text-blue-100 !border !border-blue-600 hover:!bg-blue-700 hover:!text-blue-100
}

.btn--gray-outline {
    @apply !text-gray-100 !border !border-gray-500 hover:!bg-gray-600 hover:!text-gray-100
}

.btn--pink-outline {
    @apply !text-pink-100 !border !border-pink-500 hover:!bg-pink-600 hover:!text-pink-100
}

.btn--purple-outline {
    @apply !text-purple-100 !border !border-purple-500 hover:!bg-purple-600 hover:!text-purple-100
}

.btn--indigo-outline {
    @apply !text-indigo-500 !border !border-indigo-500 hover:!bg-indigo-600 hover:!text-indigo-100
    dark:!border-indigo-200 dark:!text-indigo-200 dark:hover:!bg-indigo-600 dark:hover:!text-indigo-100
    dark:hover:!border-indigo-500
}

.btn--slate-outline {
    @apply !text-slate-100 !border !border-slate-500 hover:!bg-slate-600 hover:!text-slate-100
}

.btn--zinc-outline {
    @apply !text-zinc-100 !border !border-zinc-500 hover:!bg-zinc-600 hover:!text-zinc-100
}

.btn--orange-outline {
    @apply !text-orange-100 !border !border-orange-500 hover:!bg-orange-600 hover:!text-orange-100
}

.btn--amber-outline {
    @apply !text-amber-100 !border !border-amber-500 hover:!bg-amber-600 hover:!text-amber-100
}

.btn--yellow-outline {
    @apply !text-yellow-100 !border !border-yellow-500 hover:!bg-yellow-600 hover:!text-yellow-100
}

.btn--lime-outline {
    @apply !text-lime-100 !border !border-lime-500 hover:!bg-lime-600 hover:!text-lime-100
}

.btn--sky-outline {
    @apply !text-sky-100 !border !border-sky-500 hover:!bg-sky-600 hover:!text-sky-100
}

.btn--white-outline {
    @apply !text-gray-800 !border !border-gray-500 hover:!bg-gray-200 hover:!text-gray-800
}

.btn--primary-outline {
    @apply !text-primary !border !border-primary hover:!bg-primary hover:!text-white dark:!border-secondary dark:!text-secondary dark:hover:!bg-secondary dark:hover:!text-gray-900
}
