.ui-table .ant-pagination-options {
    @apply hidden
}

.ui-table .ant-table-thead > tr > th {
    @apply font-bold
}

.ui-table .ant-table {
    @apply dark:bg-gray-800 dark:text-gray-300
}

.ui-table .ant-table-tbody > tr > td {
    @apply dark:!border-gray-700 text-sm !px-3 dark:bg-gray-800
}

.ui-table .ant-table-thead > tr > th {
    @apply dark:bg-gray-900 dark:!border-gray-700 dark:text-gray-300 text-sm !px-3
}

.ui-table .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    @apply dark:bg-white
}

.ui-table .ant-table.ant-table-bordered > .ant-table-container,
.ui-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table,
.ui-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table {
    @apply dark:!border-gray-700
}

.ui-table .ant-table-tbody > tr.ant-table-row:hover > td,
.ui-table .ant-table-tbody > tr > td.ant-table-cell-row-hover,
.ui-table .ant-table-tbody > tr.ant-table-row-selected > td {
    @apply dark:bg-gray-700 dark:!border-gray-600
}

.ui-table td.ant-table-column-sort {
    @apply dark:!bg-gray-800
}

.ui-table .ant-table-cell-fix-left, .ant-table-cell-fix-right {
    @apply dark:bg-gray-800
}

.ui-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ui-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ui-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ui-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ui-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ui-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ui-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ui-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ui-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ui-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ui-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ui-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > .ant-table-cell-fix-right-first::after {
    @apply border-none
}

.ui-table .ant-table-sticky-holder {
    @apply bg-transparent
}

.ant-table-sticky-scroll {
    @apply hidden
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    overflow-wrap: initial !important;
}

.ui-table-fixed .ant-table-thead > tr > th.ant-table-cell:not(.ant-table-selection-column):not(.td-action),
.ui-table-fixed .ant-table-tbody > tr > td.ant-table-cell:not(.ant-table-selection-column):not(.td-action)
{
    @apply min-w-[100px]
}


.ui-table .ant-spin-container::after {
    @apply hidden
}

.ui-table-sm .ant-table-thead > tr > th,
.ui-table-sm .ant-table-tbody > tr.ant-table-row > td {
    @apply !text-[11px] !p-0.5
}
